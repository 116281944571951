import React from "react"

import Layout from "../../components/layout/layout-stp"
import Seo from "../../components/seo"

import * as style from '../../components/content/content.module.scss'

import { graphql } from "gatsby"

import Album from "../../components/album/album"

const Store = ({ data }) => (
  <Layout>

    <Seo title="Shop" bodyClass='stp' />

    <div className="column column--100">

    <span className={style.titleLowercase}>Music</span>


      <Album
        cover={data.epcover.childImageSharp.gatsbyImageData}
        title="Ontario In Your Stereo EP"
        description={`Specyal T's Debut Mixtape EP which features her single "Ice Cream Cone”`}
        // Links
        available="yes"
        stream="yes"
        buy="yes"
        paypal="Y5W8X4JNPPBLU"
        spotify="6h61b9xSv4usVofig6HasA"
        applemusic="https://music.apple.com/ca/album/ice-cream-cone-radio-edit-single/389277698"
        itunes="https://music.apple.com/ca/album/ice-cream-cone-radio-edit/389277698"
        soundcloud="https://soundcloud.com/specyal-t-productions/ice-cream-cone-specyal-t"
      />
      <Album
        cover={data.clever.childImageSharp.gatsbyImageData}
        title="That Was Clever!"
        description={`SPECYAL T PRODUCTIONS' first studio album of their signature Custom-Made Birthday Songs! New and improved! More than just happy birthday!`}
        // Links
        available="yes"
        stream="no"
        buy="yes"
        // paypal="QGYSA86BQX8WN"
        // spotify="6h61b9xSv4usVofig6HasA"
        // applemusic="https://music.apple.com/ca/album/ice-cream-cone-radio-edit-single/389277698"
        // itunes="https://music.apple.com/ca/album/ice-cream-cone-radio-edit/389277698"
        // soundcloud="https://soundcloud.com/specyal-t-productions/ice-cream-cone-specyal-t"
        // buycd="https://specyalt.s3.us-east-2.amazonaws.com/downloads/album_shesaidep.zip"
        // buycd="https://specyaltees.bigcartel.com/product/that-was-clever-lp"
        buymp3="https://specyaltees.bigcartel.com/product/that-was-clever-lp"

      />
      <Album
        cover={data.shesaid.childImageSharp.gatsbyImageData}
        title="She Said! EP"
        description={`Specyal T's latest EP is theme based consisting of six different inspired stories from a female perspective.`}
        // Links
        available="yes"
        stream="no"
        buy="yes"
        // paypal="QGYSA86BQX8WN"
        // spotify="6h61b9xSv4usVofig6HasA"
        // applemusic="https://music.apple.com/ca/album/ice-cream-cone-radio-edit-single/389277698"
        // itunes="https://music.apple.com/ca/album/ice-cream-cone-radio-edit/389277698"
        // soundcloud="https://soundcloud.com/specyal-t-productions/ice-cream-cone-specyal-t"
        directdownload="https://specyalt.s3.us-east-2.amazonaws.com/downloads/album_shesaidep.zip"
      />

    

    </div>

  </Layout>
)

export default Store


export const query = graphql`
query {
  epcover: file(relativePath: {eq: "st-store/albums/epcover.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  clever: file(relativePath: {eq: "st-store/albums/thatwasclever.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  shesaid: file(relativePath: {eq: "st-store/albums/she-said.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
    }
  }
 
  
}
`